import { createAction, props } from '@ngrx/store';
import { FileIndex } from '@wdx/shared/utils';

export const getFilePreview = createAction(
    '[File Download] Get File Preview ',
    props<{
        fileIndex: FileIndex;
    }>(),
);

export const getFilePreviewSuccess = createAction(
    '[File Download] Get File Preview Success',
    props<{
        fileIndex: FileIndex;
        fileType: string;
        fileUrl: string;
    }>(),
);

export const getFilePreviewFailure = createAction(
    '[File Download] Get File Preview Failure',
    props<{
        fileIndex: FileIndex;
        error: Error;
    }>(),
);

export const destroyFilePreview = createAction(
    '[File Download] Destroy File Preview',
    props<{
        fileIndex: string;
    }>(),
);

export const destroyFilePreviewSuccess = createAction(
    '[File Download] Destroy File Preview Success',
    props<{
        fileIndex: string;
    }>(),
);

export const getFileDownload = createAction(
    '[File Download] Get File Download',
    props<{
        fileIndex: string;
        fileName?: string;
    }>(),
);

export const deleteFile = createAction(
    '[File Download] Delete File',
    props<{
        fileIndex: string;
    }>(),
);

export const deleteFileSuccess = createAction(
    '[File Download] Delete File Success',
    props<{
        fileIndex: string;
    }>(),
);

export const deleteFileFailure = createAction(
    '[File Download] Delete File Failure',
    props<{
        fileIndex: string;
        error: Error;
    }>(),
);

export const getContextualFileDownload = createAction(
    '[File Download] Get Contextual File Download',
    props<{
        queryEntity: string;
        id: string;
        fileName?: string;
    }>(),
);

export const getContextualFilePreview = createAction(
    '[File Download] Get Contextual File Preview ',
    props<{
        queryEntity: string;
        id: string;
        fileType?: string;
    }>(),
);

export const getContextualFilePreviewSuccess = createAction(
    '[File Download] Get Contextual File Preview Success',
    props<{
        queryEntity: string;
        id: string;
        fileUrl: string;
        fileType?: string;
    }>(),
);

export const getContextualFilePreviewFailure = createAction(
    '[File Download] Get Contextual File Preview Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

import { AbstractControl, ValidatorFn } from '@angular/forms';
import { QueryExpression } from '@wdx/clmi/api-models';
import {
    MULTIPLE_OPTIONS_OPERATORS,
    NO_VALUE_OPERATORS,
} from './builder.static';

export const expressionValueValidator: ValidatorFn = (
    control: AbstractControl,
) => {
    const noValueRequired = NO_VALUE_OPERATORS.includes(
        control.get('operator').value,
    );
    if (noValueRequired) {
        return null;
    }
    const value = control.get('values').value;
    return !Array.isArray(value) ||
        value.length === 0 ||
        value.some(
            (v) =>
                ['', undefined, null].includes(v) ||
                (Array.isArray(v) && v.length === 0) ||
                (typeof v === 'object' && Object.keys(v).length === 0),
        )
        ? {
              required: true,
          }
        : null;
};

/**
 * Converts the nested values fields of multi-value expressions to a flat array as the API
 * expects them in this format for its queries
 */
export function convertBuilderToApiFormat(
    expressions: QueryExpression[],
): QueryExpression[] {
    if (!expressions || !expressions.length) {
        return expressions;
    }
    return expressions.map((expression) => {
        if (NO_VALUE_OPERATORS.includes(expression.operator)) {
            return {
                ...expression,
                values: [],
            };
        }
        if (
            MULTIPLE_OPTIONS_OPERATORS.includes(expression.operator) &&
            expression.values.length &&
            Array.isArray(expression.values[0])
        ) {
            return {
                ...expression,
                values: expression.values[0],
            };
        }
        return expression;
    });
}
